import styled from "styled-components"

const AnnouncementBannerStyles = styled.div`
  background-color: ${props => props.theme.brand.ltGold1};
  border: 1px solid ${props => props.theme.brand.ltGold0};
  border-radius: ${props => props.theme.borderRadCompact};
  padding: ${props => props.theme.spacing.space2};

  p {
    color: ${props => props.theme.grey2};
  }
`

const CardRowStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .image-frame-inline {
    flex: 0 0 ${props => props.maxWidth || "180px"};
    margin: ${props => props.theme.defaultSpacing};

    img {
      display: block;
      width: 100%;
    }
  }
`

export { AnnouncementBannerStyles, CardRowStyles }
