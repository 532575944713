import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  AnnouncementBannerStyles,
  CardRowStyles,
} from "../components/styles/LayoutStyles"
import { DIRECT_EXPRESS_WEBSITE_URL } from "../constants/pageUrls"

const PayPerksForDETransitionPage = () => (
  <Layout variant="transition">
    <SEO title="Recent Changes to PayPerks for Direct Express® | PayPerks" />
    <div className="content-wrap max-640" id="transition-2021-payperks">
      <AnnouncementBannerStyles className="mb-3">
        <p className="ts-s mb-0">
          <span className="mr-1">
            <FontAwesomeIcon
              icon={["fas", "bullhorn"]}
              size="1x"
              color="#666"
            />
          </span>{" "}
          <strong>
            PayPerks for Direct Express<sup>&reg;</sup> has ended.
          </strong>{" "}
          Find our most recent updates below.
        </p>
      </AnnouncementBannerStyles>
      <h1 className="mb-2 t-alignC">
        Recent Changes to PayPerks for{" "}
        <span className="text-unit">
          Direct Express<sup>&reg;</sup>
        </span>
      </h1>
      <CardRowStyles className="de-card-row">
        <div className="image-frame-inline">
          <StaticImage
            src="../images/programs/card-directexpress.png"
            alt="Direct Express® card"
            layout="fullWidth"
            placeholder="blurred"
          />
        </div>
      </CardRowStyles>
      <p className="c-grey1 ts-s t-alignC">
        <em>Last updated: March 31, 2021</em>
      </p>
      <hr className="hairline green1 mt-3 mb-3" />
      <h3>
        PayPerks for Direct Express<sup>&reg;</sup> has ended.
      </h3>
      <p>
        As of March 31, 2021, PayPerks for Direct Express<sup>&reg;</sup> has
        ended. Direct Express<sup>&reg;</sup> cardholders can find financial
        education directly inside their account website or mobile app. Please do
        not contact PayPerks regarding your Direct Express<sup>&reg;</sup> card.
      </p>
      <hr className="hairline dotted mt-3 mb-3" />
      <h3>What do I do if I have a question about my card?</h3>
      <p>
        You can contact Direct Express<sup>&reg;</sup> at the number on the back
        of your card or visit{" "}
        <a
          href={DIRECT_EXPRESS_WEBSITE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          www.usdirectexpress.com
        </a>{" "}
        for more information. Please <span className="upper">do not</span>{" "}
        contact PayPerks &mdash; we do not have any information about your
        Direct Express<sup>&reg;</sup> card.
      </p>
      <hr className="hairline dotted mt-3 mb-3" />
      <h3>I have a PayPerks scratcher. What do I do with it?</h3>
      <p>
        Unfortunately, there&rsquo;s nothing you can do with it anymore. You can
        keep it as a souvenir or recycle it or throw it away.
      </p>
      <hr className="hairline dotted mt-3 mb-3" />
      <h3>I won a prize on PayPerks, what happened to it?</h3>
      <p>
        All PayPerks prizes have been paid out to cardholders. There are no more
        unclaimed prizes, nor are there any future drawings.
      </p>
      <hr className="hairline dotted mt-3 mb-3" />
      <h3>A final word.</h3>
      <p>
        PayPerks was available as the education and rewards program on Direct
        Express<sup>&reg;</sup> from April 1, 2013 through March 31, 2021.
        During that time we educated nearly 2 million cardholders who took tens
        of millions of educational tutorials covering topics from how to use
        your card to financial basics to safety and privacy and more. Many
        cardholders have shared with us how our education helped them improve
        their lives. If you have a story to share, you can reach us at{" "}
        <a href="mailto:partners@payperks.com">partners@payperks.com</a>.{" "}
      </p>
      <p>
        <strong className="text-unit">Thank you!</strong>
      </p>

      <hr className="hairline green1 mt-3 mb-3" />
      <CardRowStyles className="pete-post" maxWidth="240px">
        <div className="image-frame-inline">
          <StaticImage
            src="../images/pete/pete-big-arms.png"
            alt="PayPerks Pete"
            layout="fullWidth"
            placeholder="blurred"
          />
        </div>
      </CardRowStyles>
    </div>
  </Layout>
)

export default PayPerksForDETransitionPage
